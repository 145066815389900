<template>
  <section class="hero is-fullheight is-pulled-up">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title is-4 has-text-info">
          These aren't the droids you're looking for...
        </p>
        <p class="subtitle is-6">
          You may have mistyped the address or the page may have moved
        </p>
        <router-link class="button is-soft is-rounded is-outlined" to="/">
          <span>Back to safety</span>
          <span class="icon">
            <i class="fal fa-home" />
          </span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
